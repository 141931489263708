import * as types from "./actionTypes";
import {
  getReligtions,
  getIssuerTypes,
  getRoleList,
  getGender,
  getMaritalStatus,
  getIntervals,
  getSpakingLanguages,
  getOccupations,
  getBloodTypes,
  getAllergyTypes,
  getAllergySeverity,
  getDocumentCategory,
  postRole,
} from "../../api/lookupDataApi";
import {
  getQuestions,
  getInformations,
  getAnswers,
  getHints,
} from "../../api/chatbotInformationApi";

import { getHumanBody } from "../../api/humanBodyApi";
import {
  getSymptoms,
  getGqualifiers,
  getSqualifiers,
} from "../../api/symptomApi";
import { getAllActivity } from "../../api/activityApi";

export function getReligions() {
  return function (dispatch) {
    getReligtions()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_RELIGION,
          lookup: { religion: res.data },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getIssuerTypesDispatch() {
  return function (dispatch) {
    getIssuerTypes()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_ISSUERTYPE,
          lookup: { issuerType: res.data },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getRoleListDispatch() {
  return function (dispatch) {
    getRoleList()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_ROLELIST,
          lookup: { roleList: res.data },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function postRoleDispatch(role) {
  return function (dispatch) {
    postRole(role)
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_ADDROLE,
          role: { role: res.data },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getGenderDispatch() {
  return function (dispatch) {
    getGender()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_ENUM_GENDER,
          lookup: { gender: res.data },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getBloodTypeDispatch() {
  return function (dispatch) {
    getBloodTypes()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_ENUM_BLOOD_TYPE,
          lookup: { bloodTypes: res.data },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getAllergyTypeDispatch() {
  return function (dispatch) {
    getAllergyTypes()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_ENUM_ALLERGY_TYPE,
          lookup: { allergyTypes: res.data },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getAllergySeverityDispatch() {
  return function (dispatch) {
    getAllergySeverity()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_ENUM_ALLERGY_SEVERITY,
          lookup: { allergySeverity: res },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getMaritalStatusDispatch() {
  return function (dispatch) {
    getMaritalStatus()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_ENUM_MARITAL_STATUS,
          lookup: { maritalStatus: res.data },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getIntervalDispatch() {
  return function (dispatch) {
    getIntervals()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_ENUM_INTERVALS,
          lookup: { intervals: res.data },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getSpakingLanguagesDispatch() {
  return function (dispatch) {
    getSpakingLanguages()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_SPEAKING_LANGUAGES,
          lookup: { languages: res.data },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getOccupationsDispatch() {
  return function (dispatch) {
    getOccupations()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_OCCUPATIONS,
          lookup: { occupations: res.data },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getDocumentCategoriesDispatch() {
  return function (dispatch) {
    getDocumentCategory()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_DOCUMENT_CATEGORY,
          lookup: { documentCategories: res.data },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getActivitiesDispatch() {
  return function (dispatch) {
    getAllActivity()
      .then((res) => {
        dispatch({
          type: types.APP_ACTIVITIES,
          lookup: { activities: res },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getHumanBodyDispatch() {
  return function (dispatch) {
    getHumanBody()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_HUMAN_BODY,
          lookup: { humanbody: res.data },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getSymptomsDispatch() {
  return function (dispatch) {
    getSymptoms()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_SYMPTOMS,
          lookup: { symptoms: res },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getGqualifiersDispatch() {
  return function (dispatch) {
    getGqualifiers()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_GQUALIFIER,
          lookup: { gqualifiers: res },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getSqualifiersDispatch() {
  return function (dispatch) {
    getSqualifiers()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_SQUALIFIER,
          lookup: { squalifiers: res },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getQuestionsDispatch() {
  return function (dispatch) {
    getQuestions()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_QUESTIONS,
          lookup: { questions: res },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getAnswersDispatch() {
  return function (dispatch) {
    getAnswers()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_ANSWERS,
          lookup: { answers: res },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getHintsDispatch() {
  return function (dispatch) {
    getHints()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_HINTS,
          lookup: { hints: res },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function getInformationsDispatch() {
  return function (dispatch) {
    getInformations()
      .then((res) => {
        dispatch({
          type: types.APP_LOOKUP_INFORMATIONS,
          lookup: { informations: res },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

export function setLang(lang) {
  return function (dispatch) {
    dispatch({ type: types.SAGA_CHANGE_LANGUAGE, lang });
  };
}
