import { UserManager, Log } from "oidc-client";
import { take, put, call, select } from "redux-saga/effects";
import * as types from "../redux/actions/actionTypes";
import { getUserMenu, getUserInfo } from "../api/userApi";
import {
  getHumanBodyDispatch,
  getSymptomsDispatch,
  getGqualifiersDispatch,
  getSqualifiersDispatch,
  getQuestionsDispatch,
  getAnswersDispatch,
  getInformationsDispatch,
  getHintsDispatch,
  getReligions,
  getIssuerTypesDispatch,
  getRoleListDispatch,
  getGenderDispatch,
  getMaritalStatusDispatch,
  getIntervalDispatch,
  getSpakingLanguagesDispatch,
  getOccupationsDispatch,
  getBloodTypeDispatch,
  getAllergyTypeDispatch,
  getAllergySeverityDispatch,
} from "../redux/actions/appActions";
import {
  getDrugDoseUnitDispatch,
  getDrugFormDispatch,
  getDrugTypeDispatch,
} from "../redux/actions/drugActions";

const userManagerConfig = {
  client_id: process.env.REACT_APP_CLIENT_ID,
  client_secret: "secret",
  redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URI,
  silentRequestTimeout: process.env.REACT_APP_SILENT_REQUEST_TIMEOUT,
  response_type: process.env.REACT_APP_RESPONSE_TYPE,
  scope: process.env.REACT_APP_SCOPE,
  authority: process.env.REACT_APP_AUTHORITY,
  acr_values: process.env.REACT_APP_TENANT,
};

const userManager = new UserManager(userManagerConfig);
let _accessToken;
let _expiresAt;

Log.logger = console;
Log.level = Log.INFO;

userManager.events.addUserLoaded(function (user) {
  //console.log("userLoaded", user);
});

userManager.events.addUserUnloaded(function (user) {
  //console.log("userUnloaded", user);
});

userManager.events.addSilentRenewError(function (err) {
  //console.log("silentRenewError", err);
});

function renewIdpTokenScheduler() {
  const delay = _expiresAt - Date.now();
  if (delay > 0) {
    setTimeout(() => renewIdpTokenInternal(), delay);
  }
}

function renewIdpTokenInternal() {
  userManager.signinSilent().then(
    function (user) {
      setSession(user);
    },
    function (err) {
      //TODO setup error and move to home page.
      //setErrorToken({ renewTokenStatus: "ERROR" }, { status: "ERROR" });
      //WorkAroung for login after error.
      window.location.reload();
    }
  );
}

export function getIdpAccessToken() {
  if (!_accessToken) {
    throw new Error("No access token found.");
  }
  return _accessToken;
}

export function isIdpAuthenticated() {
  if (!_expiresAt) return false;
  return new Date().getTime() < _expiresAt;
}

function* getIdpUserMenuSaga() {
  try {
    const userMenu = yield call(getUserMenu);
    yield put({ type: types.SET_USER_MENU, menu: userMenu });
  } catch (ex) {
    yield put({ type: types.SET_USER_MENU, menu: [] });
  }
}

function* getUserInfoSaga() {
  try {
    const userInfo = yield call(getUserInfo);
    yield put({ type: types.SET_USER_INFO, info: userInfo });
    const profileInfo = userInfo.profile;
    yield put({
      type: types.APP_SET_SCREEN_THEME,
      theme:
        profileInfo && profileInfo.metadata && profileInfo.metadata.theme
          ? profileInfo.metadata.theme
          : "dark",
    });
  } catch (ex) {
    yield put({ type: types.SET_USER_INFO, info: {} });
  }
}

export function* idpLoginSaga() {
  while (true) {
    yield take(types.IDP_START_LOGIN);
    yield put({ type: types.IDP_START_LOGIN });
    try {
      yield userManager.signinRedirect();
    } catch (err) {
      yield put({ type: types.AUTH_RESULT, status: "ERROR" });
      yield put({
        type: types.AUTH_RENEWTOKEN_RESULT,
        renewTokenStatus: "ERROR",
      });
    }
    const { status } = yield take(types.IDP_RESULT);
    yield put({ type: types.IDP_RESULT, status });
  }
}

export function* idpHandleAuthentication() {
  while (true) {
    yield take(types.IDP_HANDLE_AUTHENTICATION);
    try {
      const authResult = yield userManager.signinRedirectCallback();
      setSession(authResult);
      const profile = authResult.profile;
      yield put({ type: types.AUTH_SET_PROFILE, profile });
      yield* getIdpUserMenuSaga();
      yield* getUserInfoSaga();
      //TODO Connect to signalR
      yield put({ type: types.AUTH_RESULT, status: "OK" });
      yield put({
        type: types.AUTH_RENEWTOKEN_RESULT,
        renewTokenStatus: "OK",
      });
    } catch (ex) {
      console.log("error", ex);
      yield put({ type: types.AUTH_RESULT, status: "ERROR" });
      yield put({
        type: types.AUTH_RENEWTOKEN_RESULT,
        renewTokenStatus: "ERROR",
      });
    }
  }
}

function setSession(authResult) {
  const expiresAt = JSON.stringify(
    authResult.expires_in * 1000 + new Date().getTime()
  );
  const accessToken = authResult.access_token;
  _accessToken = accessToken;
  _expiresAt = expiresAt;

  renewIdpTokenScheduler();
}

export function* idpLogoutSaga() {
  yield take(types.IDP_START_LOGOUT);
  _accessToken = null;
  _expiresAt = null;
  const res = yield userManager.signoutRedirect({
    redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  });
}

const getLanguage = (state) => state.app.lang;

export function* renewIdpToken() {
  while (true) {
    const { actions } = yield take(types.IDP_START_RENEW_TOKEN);
    try {
      const authResult = yield userManager.signinSilent();
      yield call(setSession, authResult);
      const profile = authResult.profile;
      yield put({ type: types.AUTH_SET_PROFILE, profile });
      yield* getIdpUserMenuSaga();
      yield* getUserInfoSaga();
      const stateLang = yield select(getLanguage);
      if (actions && actions.oldLanguage && actions.oldLanguage !== stateLang) {
        //TODO get all items that need translation after language changes.
        yield put(getHumanBodyDispatch());
        yield put(getSymptomsDispatch());
        yield put(getGqualifiersDispatch());
        yield put(getSqualifiersDispatch());
        yield put(getQuestionsDispatch());
        yield put(getAnswersDispatch());
        yield put(getHintsDispatch());
        yield put(getInformationsDispatch());
        yield put(getReligions());
        yield put(getIssuerTypesDispatch());
        yield put(getRoleListDispatch());
        yield put(getGenderDispatch());
        yield put(getMaritalStatusDispatch());
        yield put(getIntervalDispatch());
        yield put(getSpakingLanguagesDispatch());
        yield put(getOccupationsDispatch());
        yield put(getBloodTypeDispatch());
        yield put(getAllergyTypeDispatch());
        yield put(getAllergySeverityDispatch());
        yield put(getDrugDoseUnitDispatch());
        yield put(getDrugFormDispatch());
        yield put(getDrugTypeDispatch());
      }

      yield put({ type: types.AUTH_RENEWTOKEN_RESULT, renewTokenStatus: "OK" });
    } catch (ex) {
      yield put({
        type: types.AUTH_RENEWTOKEN_RESULT,
        renewTokenStatus: "ERROR",
      });
    }
  }
}

export function* renewIdpTokenHandle() {
  while (true) {
    yield take(types.IDP_HANDLE_RENEW_TOKEN);
    try {
      const error = yield userManager.signinSilentCallback();
    } catch (ex) {
      yield put({
        type: types.AUTH_RENEWTOKEN_RESULT,
        renewTokenStatus: "ERROR",
      });
    }
  }
}
