import * as types from "../actions/actionTypes";
import initialState from "./initialState";

const lang = localStorage.getItem("i18nextLng")
  ? localStorage.getItem("i18nextLng")
  : "en";
const initState = { ...initialState.app, lang: lang };
export default function userReducer(state = initState, action) {
  switch (action.type) {
    case types.APP_SET_SCREEN_THEME:
      return { ...state, theme: action.theme };
    case types.APP_SET_SCREEN_POSITION:
      return { ...state, screenPosition: action.screenPosition };
    case types.APP_SET_LANGUAGE:
      return { ...state, lang: action.lang };
    case types.APP_LOOKUP_RELIGION:
      return {
        ...state,
        lookup: { ...state.lookup, religion: action.lookup.religion },
      };
    case types.APP_LOOKUP_ISSUERTYPE:
      return {
        ...state,
        lookup: { ...state.lookup, issuerType: action.lookup.issuerType },
      };
    case types.APP_LOOKUP_ROLELIST:
      return {
        ...state,
        lookup: { ...state.lookup, roleList: action.lookup.roleList },
      };
    case types.APP_LOOKUP_ADDROLE:
      const newRoleList = [...state.lookup.roleList];
      newRoleList.push(action.role.role);
      return {
        ...state,
        lookup: { ...state.lookup, roleList: newRoleList },
      };
    case types.APP_LOOKUP_ENUM_GENDER:
      return {
        ...state,
        lookup: { ...state.lookup, gender: action.lookup.gender },
      };
    case types.APP_LOOKUP_ENUM_BLOOD_TYPE:
      return {
        ...state,
        lookup: { ...state.lookup, bloodTypes: action.lookup.bloodTypes },
      };
    case types.APP_LOOKUP_ENUM_ALLERGY_TYPE:
      return {
        ...state,
        lookup: { ...state.lookup, allergyTypes: action.lookup.allergyTypes },
      };
    case types.APP_LOOKUP_ENUM_ALLERGY_SEVERITY:
      return {
        ...state,
        lookup: {
          ...state.lookup,
          allergySeverity: action.lookup.allergySeverity,
        },
      };
    case types.APP_LOOKUP_ENUM_MARITAL_STATUS:
      return {
        ...state,
        lookup: { ...state.lookup, maritalStatus: action.lookup.maritalStatus },
      };
    case types.APP_LOOKUP_ENUM_INTERVALS:
      return {
        ...state,
        lookup: { ...state.lookup, intervals: action.lookup.intervals },
      };
    case types.APP_LOOKUP_SPEAKING_LANGUAGES:
      return {
        ...state,
        lookup: { ...state.lookup, languages: action.lookup.languages },
      };
    case types.APP_LOOKUP_OCCUPATIONS:
      return {
        ...state,
        lookup: { ...state.lookup, occupations: action.lookup.occupations },
      };
    case types.APP_LOOKUP_DOCUMENT_CATEGORY:
      return {
        ...state,
        lookup: {
          ...state.lookup,
          documentCategories: action.lookup.documentCategories,
        },
      };
    case types.APP_ACTIVITIES:
      return {
        ...state,
        lookup: { ...state.lookup, activities: action.lookup.activities },
      };
    case types.APP_LOOKUP_DRUG_DOSE_UNIT:
      return {
        ...state,
        lookup: {
          ...state.lookup,
          drug: {
            ...state.lookup.drug,
            doseUnits: action.lookup.doseUnits,
          },
        },
      };
    case types.APP_LOOKUP_DRUG_FORM:
      return {
        ...state,
        lookup: {
          ...state.lookup,
          drug: {
            ...state.lookup.drug,
            forms: action.lookup.drugForms,
          },
        },
      };
    case types.APP_LOOKUP_DRUG_TYPE:
      return {
        ...state,
        lookup: {
          ...state.lookup,
          drug: {
            ...state.lookup.drug,
            types: action.lookup.drugTypes,
          },
        },
      };
    case types.APP_LOOKUP_HUMAN_BODY:
      return {
        ...state,
        lookup: { ...state.lookup, humanbody: action.lookup.humanbody },
      };
    case types.APP_LOOKUP_SYMPTOMS:
      return {
        ...state,
        lookup: { ...state.lookup, symptoms: action.lookup.symptoms },
      };
    case types.APP_LOOKUP_GQUALIFIER:
      return {
        ...state,
        lookup: { ...state.lookup, gqualifiers: action.lookup.gqualifiers },
      };
    case types.APP_LOOKUP_SQUALIFIER:
      return {
        ...state,
        lookup: { ...state.lookup, squalifiers: action.lookup.squalifiers },
      };
    case types.APP_LOOKUP_QUESTIONS:
      return {
        ...state,
        lookup: { ...state.lookup, questions: action.lookup.questions },
      };
    case types.APP_LOOKUP_INFORMATIONS:
      return {
        ...state,
        lookup: { ...state.lookup, informations: action.lookup.informations },
      };
    case types.APP_LOOKUP_ANSWERS:
      return {
        ...state,
        lookup: { ...state.lookup, answers: action.lookup.answers },
      };
    case types.APP_LOOKUP_HINTS:
      return {
        ...state,
        lookup: { ...state.lookup, hints: action.lookup.hints },
      };

    default:
      return state;
  }
}
