export default {
  app: {
    theme: "dark",
    screenPosition: "ltr",
    lang: "en",
    lookup: {
      religion: [],
      issuerType: [],
      roleList: [],
      gender: [],
      bloodTypes: [],
      allergyTypes: [],
      allergySeverity: [],
      maritalStatus: [],
      intervals: [],
      languages: [],
      occupations: [],
      documentCategories: [],
      humanbody: [],
      symptoms: [],
      gqualifiers: [],
      squalifiers: [],
      drug: {
        doseUnits: [],
        forms: [],
        types: [],
      },
      questions: null,
      answers: null,
      hints: [],
      informations: [],
    },
  },
  layout: {
    isSidebarOpened: true,
  },
  user: {
    info: {},
    menu: [],
  },
  auth: {
    status: "",
    renewTokenStatus: "",
    profile: {},
  },
  organization: {
    internalOrgServicePlans: null,
  },
  webRtc: {
    isConnected: null,
    error: "",
    clients: [],
  },
  internalProviderDashboard: {
    menuModules: undefined,
    toolbox: undefined,
    dragItem: undefined,
    gridLayout: undefined,
    profiles: undefined,
    selectedProfileId: -1,
    selectedProfile: undefined,
    updatedModules: [],
    globalFilter: {
      filterRange: "week",
      startDate: undefined,
      endDate: undefined,
    },
    defaultGlobalFilter: {
      filterRange: "week",
      startDate: undefined,
      endDate: undefined,
    },
    isDialogOpen: false,
    selectedSubscriberServicePlans: [],
    resizedItem: null,
  },
};
